import {
  validateCheckDepth,
  validateDue,
  validateInterval,
  validateOperatingExpenses,
  validatePrice,
} from './validation';

import CheckInput from '../Form/CheckInput';
import { DateTime } from 'luxon';
import DirectInput from '../Form/DirectInput';
import EditableInput from '../Form/EditableInput';
import React from 'react';
import { get } from 'lodash';
import numeral from 'numeral';
import classNames from 'classnames';
import getDueWarningLevel from '../Planing/helper/getDueWarningLevel';
import styled from 'styled-components';

// switch between locales
numeral.locale('de');

function shortCurrency(value?: number) {
  if (!value) return;
  return numeral(value).format('0.0[0]');
}

function getDatetime(value: string | Date | DateTime) {
  if (typeof value === 'string') {
    return DateTime.fromISO(value, { zone: 'UTC' }).toLocal().setLocale('de');
  } else if (value instanceof DateTime) {
    return value.toLocal().setLocale('de');
  } else {
    return DateTime.fromJSDate(value, { zone: 'UTC' })
      .toLocal()
      .setLocale('de');
  }
}

const Row = styled.div`
  &.due-warning {
    border: 1px solid #00000000;
    &.due-warning-1 {
      border-color: #e5b800;
    }
    &.due-warning-2 {
      border-color: #ffaa22;
    }
    &.due-warning-3 {
      border-color: #ee4444;
    }
  }
`;

export default function renderTask(
  task: any,
  item: any,
  direct: boolean,
  isEditing: boolean,
  callback: (key: string, value: any) => void,
  filterActive: boolean
) {
  const key = task.key;
  const isActive = get(item, `defaultTasks.${key}`);
  const InputEle = direct ? DirectInput : EditableInput;
  const due = get(item, `due.${key}`) || get(item, `dues.${key}`);
  const _due = getDatetime(due);
  const dueAsHTMLInputDate = _due.toFormat('yyyy-LL-dd');
  // const dueText = _due < DateTime.fromISO("1990-01-01") ? "Überfällig" : _due.toFormat("MMM yyyy");

  const operatingExpenses = get(item, `operatingExpenses.${key}`);
  const price = get(item, `price.${key}`);
  const hourlyRate =
    operatingExpenses && price ? price / operatingExpenses : undefined;

  if (filterActive && !isActive) {
    return null;
  }

  return (
    <Row
      key={key}
      className={classNames('row tasks-row', {
        ...getDueWarningLevel(_due, DateTime.local().toJSDate()),
      })}
    >
      <div className='col col-12 col-md-6 col-xl-1'>
        <CheckInput
          // readOnly={isEditing}
          short={task.title}
          value={isActive}
          callback={(value: number) =>
            callback && callback(`defaultTasks.${key}`, value)
          }
        />
      </div>

      <div className='col col-12 col-md-6 col-xl-1'>
        {task.importFields.interval || task.oneTimeExecution === false ? (
          false
        ) : (
          <CheckInput
            short={'Einmalig'}
            value={get(item, `oneTimeExecution.${key}`)}
            callback={(value: number) =>
              callback && callback(`oneTimeExecution.${key}`, value)
            }
          />
        )}
      </div>

      <div className='col col-12 col-md-6 col-xl-1-5'>
        <InputEle
          // readOnly={isEditing}
          title='Preis'
          type='currency'
          value={get(item, `price.${key}`)}
          validate={(value: number) => {
            const r = !isActive || validatePrice(value);
            // console.log(r);
            return r;
          }}
          callback={(value: number) =>
            callback && callback(`price.${key}`, value)
          }
        />
      </div>
      <div className='col col-12 col-md-6 col-xl-1-5'>
        {task.importFields.az ? (
          <InputEle
            // readOnly={isEditing}
            title={`AZ`}
            type='hours'
            value={get(item, `operatingExpenses.${key}`)}
            validate={(value: number) =>
              !isActive || validateOperatingExpenses(value)
            }
            callback={(value: number) =>
              callback && callback(`operatingExpenses.${key}`, value)
            }
          />
        ) : null}
      </div>
      <div className='col col-12 col-md-6 col-xl-1-5'>
        <label
          style={{
            display: 'inline-block',
            lineHeight: '44px',
            fontSize: '11px',
            fontWeight: 'bold',
            color: '#a1a1a1',
            padding: '0 22px',
          }}
        >
          {shortCurrency(hourlyRate)} €/Std.
        </label>
      </div>
      <div className='col col-12 col-md-6 col-xl-1-5'>
        {task.importFields.interval ? (
          <InputEle
            // readOnly={isEditing}
            title={`IV`}
            type='months'
            value={get(item, `interval.${key}`)}
            validate={(value: number) => !isActive || validateInterval(value)}
            callback={(value: number) =>
              callback && callback(`interval.${key}`, value)
            }
          />
        ) : null}
      </div>
      <div className='col col-12 col-md-6 col-xl-2'>
        {task.editable || task.importFields.due ? (
          <InputEle
            // readOnly={isEditing}
            title={`Fälligkeit`}
            type='date'
            value={dueAsHTMLInputDate}
            validate={(value: number) => !isActive || validateDue(value)}
            callback={(value: number) => {
              callback && callback(`due.${key}`, value);
            }}
          />
        ) : null}
      </div>
      <div className='col col-12 col-md-6 col-xl-1-5'>
        {task.importFields.checkDepth ? (
          <InputEle
            // readOnly={isEditing}
            title={`PT`}
            type='percentage'
            value={get(item, `checkDepth.${key}`)}
            validate={(value: number) => !isActive || validateCheckDepth(value)}
            callback={(value: number) => {
              callback && callback(`checkDepth.${key}`, value);
            }}
          />
        ) : null}
      </div>
    </Row>
  );
}
