import React, { FunctionComponent, useMemo } from 'react';
import { ReactComponent as NotifyIcon } from '../../../../assets/icons/send.svg';
import { ReactComponent as NotifiedIcon } from '../../../../assets/icons/check-circle.svg';

import StatusAction, {
  Status,
} from '../../../common/CardList/Actions/StatusAction';
import { isNotified } from '../utils/is-notified';
import { isCancelNotified } from '../utils/is-cancel-notified';
import { showNotifications } from './ShowNotificationsModal';

interface NotifyProps {
  job: any;
  readonly?: boolean;
  onNotify?: (cancel: boolean) => void;
}

const Notify: FunctionComponent<NotifyProps> = ({ job, onNotify }) => {
  const shared = useMemo(() => isNotified(job), [job]);
  const canceled = useMemo(() => isCancelNotified(job), [job]);
  const state = useMemo(() => {
    if (canceled) return Status.failed;
    if (shared) return Status.success;
    return Status.neutral;
  }, [shared, canceled]);
  return (
    <StatusAction
      state={state}
      icon={shared ? <NotifiedIcon /> : <NotifyIcon />}
      onClick={() => {
        if (!shared) {
          onNotify && onNotify(false);
        } else {
          showNotifications(job, onNotify);
        }
      }}
    >
      {!shared && !canceled && <span>Benachrichtigen</span>}
      {shared && !canceled && <span>Ben. anzeigen</span>}
      {shared && canceled && <span>Abgesagt</span>}
    </StatusAction>
  );
};

export default Notify;
