import './Tours.scss';

import { Job, Tour } from '../../context/Route';
import {
  NavLink,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import React, { Component } from 'react';
import TourLocationResponse, { OnJobChangedDate } from './TourLocationResponse';
import { get, isNil, map, reduce } from 'lodash';

import { AuthConsumer } from '../../context/AuthContext';
import { ReactComponent as CancelIcon } from '../../assets/icons/no.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import ComparisonInput from './ComparisonInput';
import ComparisonItem from './ComparisonItem';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import { Request } from '../../api/Request';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';
import renderLoading from '../SharesActions/renderLoading';
import renderLoadingModal from '../SharesActions/renderLoadingModal';

interface MatchParams {
  id: string;
}

interface ToursResponseViewProps extends RouteComponentProps<MatchParams> {}

interface Overwritten {
  actualPrice?: number;
  actualDrivingDistance?: number;
  actualDrivingHours?: number;
  actualRuWHours?: number;
  actualWorkingHours?: number;
}

interface ToursResponseViewState {
  error?: Error;
  tour?: Tour;
  saving: boolean;
  isLoaded: boolean;
  successRedirect: boolean;
  overwrittenByUser: Overwritten;
}

function roundOrNull(tour: Tour, key: string) {
  if (!tour) return 0;
  const value: number | undefined | null = get(tour, key);
  if (!value) return 0;
  return Math.round(value * 100) / 100;
}

class ToursResponseView extends Component<
  ToursResponseViewProps,
  ToursResponseViewState
> {
  state: ToursResponseViewState = {
    isLoaded: false,
    saving: false,
    tour: undefined,
    successRedirect: false,
    overwrittenByUser: {
      actualPrice: undefined,
      actualDrivingDistance: undefined,
      actualDrivingHours: undefined,
      actualRuWHours: undefined,
      actualWorkingHours: undefined,
    },
  };

  compRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];

  constructor(props: ToursResponseViewProps) {
    super(props);
    this.save = this.save.bind(this);
    this.handleChangeDistance = this.handleChangeDistance.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleChangeDrivingHours = this.handleChangeDrivingHours.bind(this);
    this.handleChangeWorkingHours = this.handleChangeWorkingHours.bind(this);
    this.handleJobChanged = this.handleJobChanged.bind(this);
    this.handleChangeRuWHours = this.handleChangeRuWHours.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async loadData() {
    const id = this.props.match.params.id;
    try {
      const tour = await Request.get('tours', id);
      await this.setState({
        isLoaded: true,
        tour,
      });
    } catch (error: any) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  async save() {
    await this.setPromisifiedState({ saving: true });
    const { id } = this.props.match.params;
    const { tour, overwrittenByUser } = this.state;
    const jobs = map(tour!.jobs, (job: Job) => {
      return {
        _id: job._id,
        actualWorkingHours: job.actualWorkingHours || job.planHours,
        actualRuWHours: job.actualRuWHours || 0,
        completed: job.completed || !!job.doneAt || false,
      };
    });
    try {
      const d = {
        actualWorkingHours: this.getValue(
          'actualWorkingHours',
          overwrittenByUser,
          tour
        ),
        actualRuWHours: this.getValue(
          'actualRuWHours',
          overwrittenByUser,
          tour
        ),
        actualDrivingHours: this.getValue(
          'actualDrivingHours',
          overwrittenByUser,
          tour
        ),
        actualPrice: this.getValue('actualPrice', overwrittenByUser, tour),
        actualDrivingDistance: this.getValue(
          'actualDrivingDistance',
          overwrittenByUser,
          tour
        ),
        jobs,
      };
      await Request.post(`tours/${id}/response`, d);
      this.setState({
        saving: false,
        successRedirect: true,
      });
    } catch (error: any) {
      alert(JSON.stringify(error));
      this.setState({
        isLoaded: true,
        saving: false,
        error,
      });
    }
  }

  handleChangePrice(value: number) {
    const { overwrittenByUser } = this.state;
    overwrittenByUser.actualPrice = value;
    this.setState({ overwrittenByUser });
  }

  handleChangeDistance(value: number) {
    const { overwrittenByUser } = this.state;
    overwrittenByUser.actualDrivingDistance = value;
    this.setState({ overwrittenByUser });
  }

  handleChangeWorkingHours(value: number) {
    const { overwrittenByUser } = this.state;
    overwrittenByUser.actualWorkingHours = value;
    this.setState({ overwrittenByUser });
  }

  handleChangeDrivingHours(value: number) {
    const { overwrittenByUser } = this.state;
    overwrittenByUser.actualDrivingHours = value;
    this.setState({ overwrittenByUser });
  }

  handleChangeRuWHours(value: number) {
    const { overwrittenByUser } = this.state;
    overwrittenByUser.actualRuWHours = value;
    this.setState({ overwrittenByUser });
  }

  handleJobChanged(
    index: number,
    { actualWorkingHours, actualRuWHours, completed }: OnJobChangedDate
  ) {
    const { tour } = this.state;
    if (!tour) return;

    (tour.jobs[index] as Job).actualWorkingHours = actualWorkingHours;
    (tour.jobs[index] as Job).actualRuWHours = actualRuWHours;
    (tour.jobs[index] as Job).failed = !completed;
    (tour.jobs[index] as Job).doneAt = completed
      ? (tour.jobs[index] as Job).planedAt
      : undefined;

    tour.actualWorkingHours = tour.jobs.reduce((sum, job) => {
      const j = job as Job;
      if (!j.doneAt) return sum;
      if (j.actualWorkingHours) return sum + j.actualWorkingHours!;
      return sum + j.planHours;
    }, 0);

    tour.actualRuWHours = tour.jobs.reduce((sum, job) => {
      const j = job as Job;
      if (!j.doneAt || !j.actualRuWHours) return sum;
      return sum + j.actualRuWHours!;
    }, 0);

    tour.actualPrice = reduce(
      tour.jobs,
      (sum, job) => {
        const j = job as Job;
        const priceMB: number = get(j, 'location.price.mb') || 0;
        const ruw = priceMB * (j.actualRuWHours || 0);
        const price = j.doneAt && j.estimatedPrice ? j.estimatedPrice : 0;
        return sum + price + ruw;
      },
      0
    );

    this.setState({ tour });
  }

  getValue(key: string, overwrittenByUser: any, tour: any) {
    let res = 0;
    if (isNil(overwrittenByUser[key])) {
      if (tour && !isNil(tour[key])) {
        res = tour[key];
      }
    } else {
      res = overwrittenByUser[key];
    }
    const rounded = Math.round(res * 100) / 100;
    console.log(rounded);
    return rounded;
  }

  resetOverwrittenByUser(key: keyof Overwritten) {
    const { overwrittenByUser } = this.state;
    overwrittenByUser[key] = undefined;
    this.setState({ overwrittenByUser });
  }

  render() {
    const { tour, overwrittenByUser, saving } = this.state;
    // const _canSave = this.canSave();
    const jobs_loc: Job[] = tour ? (tour.jobs as Job[]) : [];

    if (this.state.successRedirect)
      return <Redirect to={`/tours/${tour ? tour._id : ''}/`} />;

    if (!tour) return renderLoading();

    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='tours container-inner'>
            <InPageNavigation
              to={`/tours/${tour ? tour._id : ''}/`}
              tour={tour}
            />
            <div className='page-header page-header-line row justify-content-between'>
              <div className='col col-12 col-md-6'>
                <h1 className='page-title'>
                  {tour ? (
                    <span>
                      {`REL-Erfassung ${tour.tag} | ${tour.technician.name}`}
                    </span>
                  ) : (
                    <span />
                  )}
                </h1>
              </div>
              {hasPermission(['tour:write']) === true ? (
                <div className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
                  {tour ? (
                    <React.Fragment>
                      <p className='success float-right' onClick={this.save}>
                        <CheckIcon />
                        Abschließen
                      </p>
                      <NavLink
                        target='_self'
                        to={`/tours/${tour._id}`}
                        className='delete float-right'
                      >
                        <CancelIcon />
                        Verwerfen
                      </NavLink>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className={`page-content`}>
              <div className='row'>
                <div className='col col-12 col-xs-12 col-lg-6 col-xl-4'>
                  <ComparisonItem title='Umsatz'>
                    <ComparisonInput
                      actual={this.getValue(
                        'actualPrice',
                        overwrittenByUser,
                        tour
                      )}
                      estimated={
                        tour && tour.estimatedPrice ? tour.estimatedPrice : 0
                      }
                      overwrite={overwrittenByUser.actualPrice}
                      onReset={() => this.resetOverwrittenByUser('actualPrice')}
                      suffix=' €'
                      readOnly={false}
                      dangerForHigher={false}
                      dangerForLower={true}
                      onChange={this.handleChangePrice}
                    />
                  </ComparisonItem>
                </div>
                <div className='col col-12 col-xs-12 col-lg-6 col-xl-4'>
                  <ComparisonItem title='Fahrstrecke'>
                    <ComparisonInput
                      actual={this.getValue(
                        'actualDrivingDistance',
                        overwrittenByUser,
                        tour
                      )}
                      // showReset={
                      //   !isNil(overwrittenByUser.actualDrivingDistance)
                      // }
                      // onReset={() =>
                      //   this.resetOverwrittenByUser("actualDrivingDistance")
                      // }
                      estimated={
                        tour && tour.planDrivingDistance
                          ? Math.round(tour.planDrivingDistance * 100) / 100
                          : 0
                      }
                      suffix=' km'
                      readOnly={false}
                      dangerForHigher={true}
                      dangerForLower={false}
                      onChange={this.handleChangeDistance}
                    />
                  </ComparisonItem>
                  <ComparisonItem title='Fahrzeit'>
                    <ComparisonInput
                      actual={tour.actualDrivingHours || 0}
                      overwrite={overwrittenByUser.actualDrivingHours}
                      estimated={
                        tour && tour.planDrivingHours
                          ? Math.round(tour.planDrivingHours * 100) / 100
                          : 0
                      }
                      suffix=' h'
                      readOnly={false}
                      dangerForHigher={true}
                      onChange={this.handleChangeDrivingHours}
                    />
                  </ComparisonItem>
                </div>
                <div className='col col-12 col-xs-12 col-lg-6 col-xl-4'>
                  <ComparisonItem title='Prüfzeit'>
                    <ComparisonInput
                      actual={roundOrNull(tour, 'actualWorkingHours')}
                      onReset={() =>
                        this.resetOverwrittenByUser('actualWorkingHours')
                      }
                      estimated={roundOrNull(tour, 'planWorkingHours')}
                      suffix=' h'
                      readOnly={true}
                      dangerForHigher={true}
                      dangerForLower={false}
                      onChange={this.handleChangeWorkingHours}
                    />
                  </ComparisonItem>
                  <ComparisonItem title='R.u.W.-Zeit'>
                    <ComparisonInput
                      actual={tour.actualRuWHours || 0}
                      overwrite={overwrittenByUser.actualRuWHours}
                      onReset={() =>
                        this.resetOverwrittenByUser('actualRuWHours')
                      }
                      estimated={0}
                      suffix=' h'
                      readOnly={false}
                      dangerForHigher={false}
                      dangerForLower={false}
                      onChange={this.handleChangeRuWHours}
                    />
                  </ComparisonItem>
                </div>
              </div>

              <div className='row'>
                <div className='col col-12'>
                  <div className='row table-divider'>
                    <div className='col'>
                      <span>Einsätze</span>
                    </div>
                  </div>
                  <div className='row table-row'>
                    <div className='col'>
                      {jobs_loc.map((job: any, index: number) => {
                        const isLoc = !(job.isHome || job.isHotel);
                        return (
                          isLoc && (
                            <div key={job._id} className='route-step'>
                              <TourLocationResponse
                                job={job}
                                onJobChanged={(data) =>
                                  this.handleJobChanged(index, data)
                                }
                              />
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {saving && renderLoadingModal('Daten werden gespeichert...')}
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(ToursResponseView);
