import { HomeAccommodationJob, HotelJob, Job } from '../../../../context/Route';
import { Request } from '../../../../api/Request';

import {
  ConfirmDialogProps,
  confirmable,
  createConfirmation,
} from 'react-confirm';

import { message, Modal } from 'antd';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { sleep } from './sleep';

type EnterDeleteProps = {
  value?: Job | HotelJob | HomeAccommodationJob;
};

const Body = styled.div`
  white-space: pre-line;
  overflow-wrap: break-word;
`;

const EnterDeleteModal: FunctionComponent<
  ConfirmDialogProps<EnterDeleteProps, boolean>
> = ({ proceed, show }) => {
  return (
    <Modal
      width={600}
      title={'Job löschen?'}
      open={show}
      okText='Löschen'
      onOk={() => {
        proceed(true);
      }}
      onCancel={() => {
        proceed(false);
      }}
    >
      <Body>
        Sind Sie sicher, dass Sie den Job unwiderruflich löschen möchten?
      </Body>
    </Modal>
  );
};

function showDelete(job: Job | HotelJob | HomeAccommodationJob) {
  return createConfirmation(confirmable(EnterDeleteModal))({
    value: job,
  });
}

export async function deleteJob(job: Job | HotelJob | HomeAccommodationJob) {
  try {
    if ((await showDelete(job)) && job.tour) {
      await Request.delete('tours', job.tour, `jobs/${job._id}`);
      await sleep(1000);
    }
  } catch (error: any) {
    message.error('Fehler beim Löschen des Jobs');
  }
}
