import { reduce, keys, pickBy } from 'lodash';

const getSkillsForLocations = (locations: any[]) => {
  const result = reduce(
    locations,
    (r, l) => {
      return reduce(
        l._dues,
        (r, v, k) => {
          // remove all numbers from key to get the skill name (e.g. ovg2 -> ovg)
          const rk = k.replace(/[0-9]/g, '');
          r[rk] = r[rk] || v;
          return r;
        },
        r
      );
    },
    {}
  );
  return keys(pickBy(result));
};

export default getSkillsForLocations;
