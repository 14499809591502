import React, { FunctionComponent } from 'react';
import {
  HomeAccommodationJob,
  HotelJob,
  Job,
  Tour,
} from '../../../context/Route';

import CardListItem from '../../common/CardList/CardListItem';

import { ReactComponent as ClockIcon } from '../../../assets/icons/info1.svg';

import { Col, Row } from 'antd';
import { chain } from 'lodash';
import styled from 'styled-components';
import { AuthConsumer } from '../../../context/AuthContext';
import { PlanningConsumer } from '../../../context/PlanningContext';
import AddHome from './AddHome';
import AddHotel from './AddHotel';
import AddJob from './AddJob';
import ExtraWrapper from './Extra';
import ExtraDistance from './ExtraDistance';
import Fixed from './Fixed';
import ItemComment from './ItemComment';
import ItemNote from './ItemNote';
import PlanedAt from './PlanedAt';
import Until from './Until';
import { deleteJob } from './utils/delete-job';
import { isNotified } from './utils/is-notified';
import { sendInfoToCustomer } from './utils/notify-customer';
import Notify from './Notify/Notify';

interface TourStopListLocationItemProps {
  tour: Tour;
  job: Job;
  next?: Job | HotelJob | HomeAccommodationJob;
  editing?: boolean;
  onChanged?: () => void;
}

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
  justify-content: flex-end;
  align-items: end;
  height: 100%;
`;

const Chip = styled.div`
  display: inline-block;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 10px;
  margin: 4px 4px 4px 0px;
  background-color: rgb(241, 241, 241);
  color: #009842;
  text-decoration: none;
  outline: none;
`;

export const AddWrapper = styled.div`
  justify-content: space-between !important;
  display: flex !important;
  max-width: 120px !important;
  svg {
    margin-right: 0rem !important;
    cursor: pointer;
    fill: #009842;
    stroke: #009842;
    &:hover {
      fill: #00a854;
      stroke: #00a854;
    }
  }
`;

const TourStopListLocationItem: FunctionComponent<
  TourStopListLocationItemProps
> = ({ tour, job, next, editing, onChanged }) => {
  const handleSaveNote = (value: string) => {
    console.log(value);
  };

  const handleSaveComment = (value: string) => {
    console.log(value);
  };

  const renderAdd = () => (
    <AddWrapper>
      <AddJob onChanged={onChanged} before={job} />
      <AddHotel onChanged={onChanged} before={job} />
      <AddHome
        onChanged={onChanged}
        before={job}
        technician={tour.technician}
      />
    </AddWrapper>
  );

  const renderExtra = () => {
    if (editing)
      return <ExtraWrapper editing={true}>{renderAdd()}</ExtraWrapper>;
    if (next) return <ExtraDistance job={job} next={next} />;
    return false;
  };

  return (
    <AuthConsumer>
      {({ user }) => (
        <PlanningConsumer>
          {({ setHighlight }) => (
            <CardListItem
              id={job._id}
              type='location'
              onMouseEnter={() => {
                setHighlight && setHighlight(job._id ?? '');
              }}
              onMouseLeave={() => {
                setHighlight && setHighlight('');
              }}
              link={`/administration/locations/${job.location._id}`}
              leading={(job.sort + 1).toString()}
              title={`[${job.location.tag}] ${job.location.name}`}
              subtitle={`${job.address.street} ${job.address.postalCode} ${job.address.city}`}
              extra={renderExtra()}
              action1={
                <PlanedAt
                  job={job}
                  onChanged={onChanged}
                  readonly={isNotified(job)}
                />
              }
              action2={<Until job={job} onChanged={onChanged} />}
              action3={<Fixed job={job} onChanged={onChanged} />}
              action4={
                <Notify
                  job={job}
                  onNotify={async (cancel) => {
                    await sendInfoToCustomer(job, tour, user.email, cancel);
                    onChanged && onChanged();
                  }}
                />
              }
              onDelete={
                editing
                  ? async () => {
                      await deleteJob(job);
                      onChanged && onChanged();
                    }
                  : undefined
              }
            >
              <Row>
                <Col xs={24} xl={12}>
                  <p>
                    <ClockIcon className='comment' />
                    {job.planHours?.toLocaleString() ?? '?'}h |{' '}
                    {job.estimatedPrice?.toLocaleString() ?? '?'}€
                  </p>
                  <ItemComment job={job} onSave={handleSaveComment} />
                  <ItemNote job={job} onSave={handleSaveNote} />
                </Col>
                <Col xs={24} xl={12}>
                  <Chips>
                    {chain(job.tasks)
                      .pickBy((v) => v === true)
                      .map((_, key) => (
                        <Chip key={key}>{key.toUpperCase()}</Chip>
                      ))
                      .value()}
                  </Chips>
                </Col>
              </Row>
            </CardListItem>
          )}
        </PlanningConsumer>
      )}
    </AuthConsumer>
  );
};

export default TourStopListLocationItem;
