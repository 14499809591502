import './CustomerGroup.scss';

import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import Table, { SELECTION_TYPE, TableOptions } from '../Table/Table';
import { debounce, filter, pick } from 'lodash';

import { AuthConsumer } from '../../context/AuthContext';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { Request } from '../../api/Request';
import Search from '../Search/Search';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';
import qs from 'query-string';

const defaultOptions = {
  sort: 'name',
  desc: false,
  page: 0,
  limit: 25,
  fuzzy: true,
};

type CustomerGroup = any;

interface MatchParams {
  id: string;
}

interface CustomerGroupListProps extends RouteComponentProps<MatchParams> {}

interface CustomerGroupListOptions extends TableOptions {
  q?: string | null;
  fuzzy?: boolean;
}

interface CustomerGroupListState {
  error?: Error;
  loading: boolean;
  searching: boolean;
  options: CustomerGroupListOptions;
  items: CustomerGroup[];
  selectedItems: CustomerGroup[];
  maxItems?: number;
}

class CustomerGroupList extends Component<
  CustomerGroupListProps,
  CustomerGroupListState
> {
  state: CustomerGroupListState = {
    loading: true,
    searching: false,
    options: defaultOptions,
    maxItems: 0,
    items: [],
    selectedItems: [],
  };

  handleSearchDebounced = debounce(this.handleSearch.bind(this), 440);

  header(searching: boolean) {
    return [
      {
        key: 'name',
        width: '2-5',
        sort: searching ? false : true,
        title: 'Kundengruppe',
      },
    ];
  }

  constructor(props: CustomerGroupListProps) {
    super(props);
    this.handleOptionsChanged = this.handleOptionsChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.removeSelectedCustomerGroups =
      this.removeSelectedCustomerGroups.bind(this);
  }

  async componentDidMount() {
    await this.loadOptionsFromURL(this.props.location);
    this.loadData();
  }

  loadOptionsFromURL(location: { search: string }) {
    const data = qs.parse(location.search);
    return new Promise<void>((resolve) => {
      const s: CustomerGroupListOptions = {};
      s.sort = (data.sort as string) || defaultOptions.sort;
      s.page = Number(data.page) || defaultOptions.page;
      s.desc = Boolean(data.desc) || defaultOptions.desc;
      s.limit = Number(data.limit) || defaultOptions.limit;
      s.q = data.q as string;
      s.fuzzy = data.fuzzy ? data.fuzzy === 'true' : defaultOptions.fuzzy;
      this.setState({ options: s }, async () => resolve());
    });
  }

  storeOptionsAtURL() {
    const s = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'q',
      'fuzzy',
    ]);
    const stringified = qs.stringify(s);
    window.history.replaceState(s, 'safePlan', `?${stringified}`);
  }

  async handleSearch(query: string | undefined, fuzzy: boolean) {
    this.setState(
      {
        loading: true,
        options: {
          limit: undefined,
          page: undefined,
          desc: undefined,
          sort: 'score',
          q: query,
          fuzzy,
        },
      },
      () => {
        this.storeOptionsAtURL();
        this.loadData();
      }
    );
  }

  async loadData() {
    const s = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'q',
      'fuzzy',
    ]);
    const result: {
      search: boolean;
      items: any[];
      total: number;
    } = await Request.list('customer-groups', s);
    this.setState({
      loading: false,
      searching: result.search,
      items: result.items,
      maxItems: result.search ? 0 : result.total,
    });
  }

  handleOptionsChanged(opts: CustomerGroupListOptions) {
    this.setState({ loading: true, options: opts }, () => {
      this.storeOptionsAtURL();
      this.loadData();
    });
  }

  handleSelectionChanged(
    customergroupsToSet: CustomerGroup[],
    selection: boolean
  ) {
    const { selectedItems } = this.state;
    if (selection) {
      this.setState({
        selectedItems: selectedItems.concat(customergroupsToSet),
      });
    } else {
      const ids = customergroupsToSet.map((l) => l._id);
      const value = filter(selectedItems, (l) => !ids.includes(l._id));
      this.setState({ selectedItems: value });
    }
  }

  async removeSelectedCustomerGroups() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Sind Sie sich sicher?')) {
      try {
        this.setState({ loading: true });
        const { selectedItems } = this.state;
        await Promise.all(
          selectedItems.map((s) => {
            return Request.delete('customer-groups', s._id);
          })
        );
        this.loadData();
      } catch (error: any) {
        this.setState({
          loading: false,
          error,
        });
      }
    }
  }

  render() {
    const { items, options, maxItems, selectedItems, loading } = this.state;
    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='customer-groups container-inner container-inner-list'>
            <div className='page-header row justify-content-between'>
              <div className='col col-12 col-md-6'>
                <h1 className='page-title'>Kundengruppen verwalten</h1>
              </div>
              <div className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
                <Search
                  value={options.q}
                  onChange={this.handleSearchDebounced}
                />
                {hasPermission(['customer-group:write']) === true ? (
                  <NavLink
                    className='float-right'
                    to={`/administration/customer-groups/new`}
                  >
                    <PlusIcon />
                    Hinzufügen
                  </NavLink>
                ) : null}
                {hasPermission(['customer-group:delete-multi']) === true &&
                selectedItems.length > 0 ? (
                  <div onClick={this.removeSelectedCustomerGroups}>
                    <TrashIcon />
                    <p>Ausgewählte löschen</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='row page-content'>
              <div className='col col-12'>
                <Table
                  loading={loading}
                  filterable={false}
                  selectable={
                    hasPermission(['customer-group:delete-multi']) === true
                      ? SELECTION_TYPE.selectAll
                      : SELECTION_TYPE.disabled
                  }
                  options={options}
                  header={this.header(this.state.searching)}
                  items={items}
                  maxItems={maxItems}
                  handleOptionsChanged={this.handleOptionsChanged}
                  selection={selectedItems}
                  handleSelectionChanged={this.handleSelectionChanged}
                  link={'/administration/customer-groups'}
                />
              </div>
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(CustomerGroupList);
