import {
  ConfirmDialogProps,
  confirmable,
  createConfirmation,
} from 'react-confirm';

import { Button, Modal } from 'antd';
import React, { FunctionComponent, useMemo, useState } from 'react';
import styled from 'styled-components';

import { debounce } from 'lodash';
import { DebounceInput } from 'react-debounce-input';
import { Geocoding } from '../../../../api/MapboxGeo';
import DirectInput from '../../../Form/DirectInput';
import { FormProvider } from '../../../../context/FormContext';
import { Job } from '../../../../context/Route';
import { on } from 'events';

type EnterHotalProps = {
  value?: any;
  onNotify?: () => void;
  onCancelNotify?: () => void;
};

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
`;

const Item = styled.li<{ danger?: boolean }>`
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 2rem;
  color: ${(props) => (props.danger ? 'red' : 'black')};
`;

const ShowNotificationsModal: FunctionComponent<
  ConfirmDialogProps<EnterHotalProps, false>
> = ({ proceed, value, show, onNotify, onCancelNotify }) => {
  return (
    <Modal
      width={600}
      title={'Kundenbenachrichtigungen'}
      open={show}
      onCancel={() => proceed(false)}
      footer={[
        <Button
          key='submit'
          danger
          onClick={() => {
            onCancelNotify && onCancelNotify();
            proceed(false);
          }}
        >
          Absagen
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            onNotify && onNotify();
            proceed(false);
          }}
        >
          Erneut benachrichtigen
        </Button>,
        <Button
          key='back'
          onClick={() => {
            proceed(false);
          }}
        >
          Schließen
        </Button>,
      ]}
    >
      <Wrapper>
        {value.customerNotification?.map((notification: any) => (
          <Item
            key={notification.id}
            danger={notification.layoutKey === 'cancel'}
          >
            <b>
              {new Date(notification.createdAt).toLocaleString('de-DE', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
              {notification.layoutKey === 'cancel' ? ' [Absage]' : ''}
            </b>
            {
              <div>
                {notification.target?.split(',').map((line: string) => (
                  <div key={line}>{line}</div>
                ))}
              </div>
            }
          </Item>
        ))}
      </Wrapper>
    </Modal>
  );
};

export function showNotifications(
  value: Job | undefined,
  onNotify?: (cancel: boolean) => void
) {
  return createConfirmation(confirmable(ShowNotificationsModal))({
    value,
    onNotify: () => onNotify && onNotify(false),
    onCancelNotify: () => onNotify && onNotify(true),
  });
}
