import React, { useState } from 'react';

import { ReactComponent as CheckBoldIcon } from '../../assets/icons/check-bold.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/date.svg';
import { DateTime } from 'luxon';
import { Job } from '../../context/Route';
import { NavLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { ReactComponent as WrenchIcon } from '../../assets/icons/wrench.svg';
import classNames from 'classnames';
import { get } from 'lodash';
import getLocalFormat from './getLocalFormat';

export interface OnJobChangedDate {
  actualWorkingHours?: number;
  actualRuWHours?: number;
  completed: boolean;
}

interface TourLocationResponseProps {
  job: Job;
  onJobChanged: (data: OnJobChangedDate) => void;
}

export default function TourLocationResponse(props: TourLocationResponseProps) {
  const [actualWorkingHours, setActualWorkingHours] = useState<number>(
    props.job.actualWorkingHours || props.job.planHours
  );
  const [completed, setCompleted] = useState<boolean>(
    props.job.completed || !!props.job.doneAt
  );
  const [actualRuWHours, setActualRuWHours] = useState<number>(
    props.job.actualRuWHours || 0
  );

  const renderPlanDate = () => {
    const d =
      typeof props.job.planedAt === 'string'
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          DateTime.fromISO(props.job.planedAt!)
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          DateTime.fromJSDate(props.job.planedAt!);
    return d
      .setLocale('de')
      .toLocaleString(getLocalFormat(props.job.planedAtTimeByUser));
  };

  const locationID = get(props, 'job.location._id');

  return (
    <React.Fragment>
      <div className='route-step-icon'>
        <WrenchIcon />
      </div>
      <div
        className={classNames('route-step-content', {
          'no-location': !locationID,
        })}
      >
        <div className='row'>
          <div className='col col-3'>
            <NavLink
              to={`/administration/locations/${locationID || ''}`}
              target='_blank'
            >
              <p className='name'>
                {locationID ? props.job.location.name : 'Standort gelöscht'}
              </p>
            </NavLink>
            {props.job.address ? (
              <p className='address'>
                {props.job.address.street}, {props.job.address.postalCode}{' '}
                {props.job.address.city}
              </p>
            ) : (
              <p className='address missing'>Bitte Adresse eingeben..</p>
            )}
          </div>
          <div className='col col-2'>
            <p className='full-height'>
              {locationID ? props.job.location.tag : ''}
            </p>
          </div>
          <div className='col col-2'>
            <p className='date full-height'>
              <ClockIcon />
              {renderPlanDate()}
            </p>
          </div>
          <div className='col col-2 col-input'>
            <p>Prüfzeit</p>
            <NumberFormat
              thousandSeparator='.'
              decimalSeparator=','
              value={actualWorkingHours}
              prefix=''
              suffix=' h'
              decimalScale={2}
              onValueChange={(values) => {
                if (!values.floatValue) return;
                setActualWorkingHours(values.floatValue);
                props.onJobChanged({
                  actualRuWHours,
                  actualWorkingHours: values.floatValue,
                  completed,
                });
              }}
            />
          </div>
          <div className='col col-2 col-input'>
            <p>R.u.W.-Zeit</p>
            <NumberFormat
              thousandSeparator='.'
              decimalSeparator=','
              value={actualRuWHours}
              prefix=''
              suffix=' h'
              decimalScale={2}
              onValueChange={(values) => {
                if (!values.floatValue) return;
                setActualRuWHours(values.floatValue);
                props.onJobChanged({
                  actualRuWHours: values.floatValue,
                  actualWorkingHours,
                  completed,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames('route-step-actions', {
          'no-location': !locationID,
        })}
      >
        <div
          className={classNames(
            'float-right',
            'tour-location-response-selection',
            {
              check: completed,
            }
          )}
        >
          <input
            type='checkbox'
            id={`tour-location-response-selection-${props.job._id}`}
            checked={completed}
            onChange={(event) => {
              setCompleted(event.target.checked);
              props.onJobChanged({
                actualRuWHours,
                actualWorkingHours,
                completed: event.target.checked,
              });
            }}
          />
          <label
            className={`check`}
            htmlFor={`tour-location-response-selection-${props.job._id}`}
          >
            <CheckBoldIcon />
          </label>
          <p>Abgeschloss</p>
        </div>
      </div>
    </React.Fragment>
  );
}
