import styled from 'styled-components';

export const PageHeaderActions = styled.ul`
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  word-break: break-all;
`;

export const PageHeaderAction = styled.li<{ danger?: boolean }>`
  cursor: pointer;
  padding: 0 1rem;
  display: inline;
  p {
    display: inline-block;
    color: #a1a1a1;
  }
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 12px 3px 0;
    fill: #a1a1a1;
    stroke: #a1a1a1;
    g {
      fill: #a1a1a1;
      stroke: #a1a1a1;
    }
  }
  &:hover {
    p,
    svg {
      color: ${({ danger }) => (danger ? '#e44' : '#009842')} !important;
      fill: ${({ danger }) => (danger ? '#e44' : '#009842')} !important;
      stroke: ${({ danger }) => (danger ? '#e44' : '#009842')} !important;
    }
  }
`;
