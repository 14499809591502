export function isCancelNotified(job: any) {
  const o = job && job.sharedAt && job.sharedAt.info;
  const n =
    job !== undefined &&
    job.customerNotification !== undefined &&
    job.customerNotification.length > 0;
  const c =
    job &&
    job.customerNotification &&
    job.customerNotification.some(
      (notification: any) => notification.layoutKey === 'cancel'
    );
  return (n || o) && c;
}
